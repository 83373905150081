import mapSliceToComponentUniversal from '~/imports/universal_page'
import { STORYBLOK_SLICES } from '~/lib/storyblok-types'

import HeroMain from '~/components/Slices/HeroMain'
import ListWorkshops from '~/components/Slices/ListWorkshops'
import PushGrid from '~/components/Slices/PushGrid'
import PushProducts from '~/components/Slices/PushProducts'

export default {
  ...mapSliceToComponentUniversal,
  [STORYBLOK_SLICES.HERO_MAIN]: HeroMain,
  [STORYBLOK_SLICES.PUSH_GRID]: PushGrid,
  [STORYBLOK_SLICES.PUSH_PRODUCTS]: PushProducts,
  [STORYBLOK_SLICES.LIST_WORKSHOPS]: ListWorkshops,
}
