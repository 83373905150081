import processEnvPublic from 'unlikely-env/public'
import mapSliceToComponent from '~/imports/home_page'
import { PAGE } from '~/lib/fetch-links'
import { STORYBLOK_TYPES } from '~/lib/storyblok-types'

import SliceManager from '~/components/Abstracts/SliceManager'
import Tracking from '~/components/Abstracts/Tracking'

import { getStaticPropsFunction } from '~/data/page-data'
import { DefaultPageData } from '~/data/page-data/serializer'

function HomePage({ slices }: DefaultPageData) {
  return (
    <>
      <SliceManager
        mapSliceTypeToComponent={mapSliceToComponent}
        slicesData={slices}
      />
      <Tracking template="index" />
    </>
  )
}

export default HomePage

export const getStaticProps = getStaticPropsFunction({
  type:
    processEnvPublic('NEXT_PUBLIC_MAINTENANCE_MODE') === 'true'
      ? STORYBLOK_TYPES.COMING_SOON
      : STORYBLOK_TYPES.HOMEPAGE,
  fetchLinks: PAGE,
})
